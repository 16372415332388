.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(0, 0, 0, 0.696);
}
.appVideos {
	width: 90vw;
	display: grid;

	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	justify-content: start;
}
body {
	background-color: rgb(255, 255, 255);
	font-family: fantasy;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
