.listContainer {
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #b10ac0;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	background-color: rgb(24, 24, 24);
}

.tb {
	border-collapse: collapse;
	width: 100%;
	color: rgba(255, 255, 255, 0.998);
}

.head {
	background-color: rgb(24, 24, 24);
	border: solid 2px #b10ac0;
	text-align: center;
	color: rgba(255, 255, 255, 0.998);
	font-size: 25px;
}

.head th {
	padding: 10px;
	padding-left: 3px;
	padding-right: 3px;
}

.body {
	text-align: center;
	padding: 3px;
	background-color: rgb(24, 24, 24);
	text-decoration: none;
}

.body td {
	border-top: solid 1px #818181;
	border-bottom: solid 1px #818181;
	border-left: solid 1px #818181;
	padding: 10px;
	text-decoration: none;
}
.body td a {
	color: rgba(255, 255, 255, 0.998);
	text-decoration: none;
}
.body td:hover {
	background-color: rgba(255, 0, 187, 0.475);
	cursor: pointer;
}
.body td:first-child {
	text-align: center;
}

.body td:last-child {
	text-align: center;
}
