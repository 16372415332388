body {
	background-color: rgb(18, 18, 18);
}
.homeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
}
.homeTitle {
	font-family: fantasy;
	color: rgb(255, 0, 200);
	font-size: 60px;
	text-align: center;
}
.homeSubtitles {
	font-family: fantasy;
	color: rgba(201, 0, 228, 0.753);
	font-size: 35px;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	transition: text-shadow 0.3s ease;
}

.homeSubtitles:hover {
	text-shadow: 0 0 5px rgba(201, 0, 228, 0.753);
}
.containerFlags {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 60vh;
}
@media (min-width: 1080px) {
	.containerFlags {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 60vh;
	}
}

.flags {
	height: 200px;
	margin: 20px;
	transition: 0.2s ease-in-out;
}
.flags:hover {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 24px 3px rgba(255, 255, 255, 0.75);
	-moz-box-shadow: 0px 0px 24px 3px rgba(255, 255, 255, 0.75);
	box-shadow: 0px 0px 24px 3px rgba(255, 255, 255, 0.75);
}
