.homeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-items: center;
}
.englishTitle {
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	color: rgba(255, 255, 255, 0.753);
	font-size: 60px;
	text-align: center;
}
.englishSubtitles {
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	color: rgba(201, 201, 201, 0.753);
	font-size: 35px;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	transition: 0.3s ease-in-out;
}
.gameContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
@media (min-width: 1080px) {
	.gameContainer {
		display: flex;
		flex-direction: row;
	}
}

.cards {
	height: 250px;
	width: 200px;
	border: solid 1px rgba(128, 128, 128, 0.197);
	border-radius: 10px;
	padding: 10px;
	transition: 0.4s ease-in-out;
	margin: 25px;
}
.cards:hover {
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.75);
	-moz-box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.75);
	box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.75);
	color: rgba(172, 12, 164, 0.753);
	/*text-shadow: 0 0 5px rgba(255, 255, 255, 0.753);*/
}
