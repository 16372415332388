.a1Container {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: #e2e2e2;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	background-color: rgb(21, 21, 21);
}
.btn {
	background-color: rgb(19, 19, 19);
	color: #b10ac0;
	width: 100px;
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	height: 50px;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 600;
}
.btn:hover {
	background-color: #b10ac0;
	color: rgb(19, 19, 19);
	cursor: pointer;
}
.btnStart {
	width: 200px;
	height: 60px;
	font-size: 30px;
	font-weight: 700;
	color: rgb(17, 17, 17);
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	background-color: #b10ac0;
	border-radius: 10px;
	border: solid 1px #b10ac0;
}
.btnStart:hover {
	border: solid 1px #b10ac0;
	color: #b10ac0;
	background-color: rgb(19, 19, 19);
	cursor: pointer;
}
.inputText {
	height: 45px;
	width: 300px;
	font-size: 30px;
	color: rgba(0, 0, 0, 0.733);
	border-radius: 10px;
	font-weight: 600;
}
.correctText {
	color: rgba(182, 3, 12, 0.555);
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.presenText {
	font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
	color: rgb(218, 3, 14);
	font-size: 55px;
	margin: 0px;
}
.formDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	height: 120px;
	justify-content: space-between;
}

.timer {
	margin: 0px;
	position: relative;
	font-size: 48px;
	color: #b10ac0;
	animation: timerAnimation 1s infinite cubic-bezier(1, 1, 1, 1);
}
